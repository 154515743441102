define("ember-data-model-fragments/attributes/fragment-owner", ["exports", "@ember/debug", "@ember/object", "ember-data-model-fragments/fragment", "@ember-data/store/-private"], function (_exports, _debug, _object, _fragment, _private) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fragmentOwner;
  /**
   `MF.fragmentOwner` defines a read-only attribute on a `MF.Fragment`
   instance. The attribute returns a reference to the fragment's owner
   record.
  
   Example
  
   ```javascript
   App.Person = DS.Model.extend({
      name: MF.fragment('name')
    });
  
   App.Name = MF.Fragment.extend({
      first: DS.attr('string'),
      last: DS.attr('string'),
      person: MF.fragmentOwner()
    });
   ```
  
   @namespace MF
   @method fragmentOwner
   @return {Attribute}
   */
  function fragmentOwner() {
    // eslint-disable-next-line ember/require-computed-property-dependencies
    return (0, _object.computed)(function () {
      (true && !((0, _fragment.isFragment)(this)) && (0, _debug.assert)('Fragment owner properties can only be used on fragments.', (0, _fragment.isFragment)(this)));
      const recordData = (0, _private.recordDataFor)(this);
      const owner = recordData.getFragmentOwner();
      if (!owner) {
        return null;
      }
      return owner._fragmentGetRecord();
    }).meta({
      isFragmentOwner: true
    }).readOnly();
  }
});