define("ember-l10n/helpers/n", ["exports", "ember-l10n/helpers/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This helper provides gettext pluralization for message ids.
   * It takes singular and plural message ids as well as actual
   * amount as positional arguments. All placeholders can be
   * provided through named arguments (hash).
   *
   * ```html
   * {{n '{{count}} apple' '{{count}} apples' someBoundProperty}}
   * ```
   *
   * @namespace Helper
   * @class N
   * @extends Ember.Helper
   * @public
   */
  class NHelper extends _base.default {
    compute([msgid, msgidPlural, count], hash) {
      let {
        l10n
      } = this;
      if (!msgid) {
        return msgid;
      }
      return l10n.n(msgid, msgidPlural, count, hash);
    }
  }
  _exports.default = NHelper;
});