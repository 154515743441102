define("ember-l10n/helpers/pn", ["exports", "ember-l10n/helpers/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This helper provides contextual plural message, where context has to
   * be given as 4th argument, otherwise just works the same as `n` helper.
   *
   * ```html
   * {{pn '{{count}} apple' '{{count}} apples' someBoundProperty 'context'}}
   * ```
   *
   * @namespace Helper
   * @class PN
   * @extends Ember.Helper
   * @public
   */
  class PNHelper extends _base.default {
    compute([msgid, msgidPlural, count, msgctxt], hash) {
      let {
        l10n
      } = this;
      if (!msgid) {
        return msgid;
      }
      return l10n.pn(msgid, msgidPlural, count, msgctxt, hash);
    }
  }
  _exports.default = PNHelper;
});