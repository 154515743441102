define("ember-data-model-fragments/attributes/fragment-array", ["exports", "@ember/debug", "@ember/object", "@ember/utils", "@ember/array", "@ember-data/store/-private", "ember-data-model-fragments/fragment", "ember-data-model-fragments/util/meta-type-for", "ember-data-model-fragments/array/fragment"], function (_exports, _debug, _object, _utils, _array, _private, _fragment, _metaTypeFor, _fragment2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fragmentArray;
  /**
   `MF.fragmentArray` defines an attribute on a `DS.Model` or `MF.Fragment`.
   Much like `DS.hasMany`, it creates a property that returns an array of
   fragments of the given type. The array is aware of its original state and so
   has a `hasDirtyAttributes` property and a `rollback` method.
  
   It takes an optional hash as a second parameter, currently supported options
   are:
  
   - `defaultValue`: An array literal or a function to be called to set the
   attribute to a default value if none is supplied. Values are deep copied
   before being used. Note that default values will be passed through the
   fragment's serializer when creating the fragment. Defaults to an empty
   array.
   - `polymorphic`: Whether or not the fragments in the array can be child
   classes of the given type.
   - `typeKey`: If `polymorphic` is true, the property to use as the fragment
   type in the normalized data. Defaults to `type`.
  
   Example
  
   ```javascript
   App.Person = DS.Model.extend({
      addresses: MF.fragmentArray('address')
    });
  
   App.Address = MF.Fragment.extend({
      street: DS.attr('string'),
      city: DS.attr('string'),
      region: DS.attr('string'),
      country: DS.attr('string')
    });
   ```
  
   @namespace MF
   @method fragmentArray
   @param {String} type the fragment type (optional)
   @param {Object} options a hash of options
   @return {Attribute}
   */
  function fragmentArray(type, options) {
    options = options || {};
    const metaType = (0, _metaTypeFor.default)('fragment-array', type, options);
    const meta = {
      modelName: type,
      type: metaType,
      isAttribute: true,
      isFragment: true,
      kind: 'fragment-array',
      options
    };

    // eslint-disable-next-line ember/require-computed-property-dependencies
    return (0, _object.computed)({
      get(key) {
        const recordData = (0, _private.recordDataFor)(this);
        if (recordData.getFragment(key) === null) {
          return null;
        }
        let fragmentArray = recordData._fragmentArrayCache[key];
        if (!fragmentArray) {
          fragmentArray = _fragment2.default.create({
            modelName: type,
            store: this.store,
            recordData,
            key
          });
          recordData._fragmentArrayCache[key] = fragmentArray;
        }
        return fragmentArray;
      },
      set(key, value) {
        (true && !(value === null || (0, _array.isArray)(value) && value.every(v => (0, _fragment.isFragment)(v) || (0, _utils.typeOf)(v) === 'object')) && (0, _debug.assert)('You must pass an array of fragments, or null to set a fragmentArray', value === null || (0, _array.isArray)(value) && value.every(v => (0, _fragment.isFragment)(v) || (0, _utils.typeOf)(v) === 'object')));
        const recordData = (0, _private.recordDataFor)(this);
        if (value === null) {
          recordData.setDirtyFragment(key, null);
          return null;
        }
        let fragmentArray = recordData._fragmentArrayCache[key];
        if (!fragmentArray) {
          fragmentArray = _fragment2.default.create({
            modelName: type,
            store: this.store,
            recordData,
            key
          });
          recordData._fragmentArrayCache[key] = fragmentArray;
        }
        fragmentArray._setFragments(value);
        return fragmentArray;
      }
    }).meta(meta);
  }
});