define("ember-l10n/utils/fetch-json-file", ["exports", "rsvp"], function (_exports, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchJsonFile = fetchJsonFile;
  function fetchJsonFile(fileName) {
    return new _rsvp.Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open('GET', fileName);
      request.addEventListener('load', function () {
        try {
          let {
            responseText
          } = this;
          let json = JSON.parse(responseText);
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
      request.addEventListener('error', reject);
      request.send();
    });
  }
});