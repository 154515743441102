define("ember-data-model-fragments/record-data", ["exports", "ember-data/-private", "@ember-data/model/-private", "@ember-data/store/-private", "@ember/debug", "@ember/utils", "@ember/array", "ember-data-model-fragments/fragment", "ember-data-model-fragments/util/instance-of-type"], function (_exports, _private, _private2, _private3, _debug, _utils, _array, _fragment, _instanceOfType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/use-ember-data-rfc-395-imports

  class FragmentBehavior {
    constructor(recordData, definition) {
      this.recordData = recordData;
      this.definition = definition;
    }
    getDefaultValue(key) {
      const {
        options
      } = this.definition;
      if (options.defaultValue === undefined) {
        return null;
      }
      let defaultValue;
      if (typeof options.defaultValue === 'function') {
        const record = this.recordData._fragmentGetRecord();
        defaultValue = options.defaultValue.call(null, record, options, key);
      } else {
        defaultValue = options.defaultValue;
      }
      (true && !(defaultValue === null || (0, _utils.typeOf)(defaultValue) === 'object' || (0, _fragment.isFragment)(defaultValue)) && (0, _debug.assert)("The fragment's default value must be an object or null", defaultValue === null || (0, _utils.typeOf)(defaultValue) === 'object' || (0, _fragment.isFragment)(defaultValue)));
      if (defaultValue === null) {
        return null;
      }
      if ((0, _fragment.isFragment)(defaultValue)) {
        (true && !((0, _instanceOfType.default)(defaultValue.store.modelFor(this.definition.modelName), defaultValue)) && (0, _debug.assert)(`The fragment's default value must be a '${this.definition.modelName}' fragment`, (0, _instanceOfType.default)(defaultValue.store.modelFor(this.definition.modelName), defaultValue)));
        const recordData = (0, _private3.recordDataFor)(defaultValue);
        recordData.setFragmentOwner(this.recordData, key);
        return recordData;
      }
      return this.recordData._newFragmentRecordData(this.definition, defaultValue);
    }
    pushData(fragment, canonical) {
      (true && !(fragment === null || fragment instanceof _private.RecordData) && (0, _debug.assert)('Fragment value must be a RecordData', fragment === null || fragment instanceof _private.RecordData));
      (true && !(canonical === null || (0, _utils.typeOf)(canonical) === 'object') && (0, _debug.assert)('Fragment canonical value must be an object or null', canonical === null || (0, _utils.typeOf)(canonical) === 'object'));
      if (canonical === null) {
        // server replaced fragment with null
        return null;
      }
      if (fragment) {
        // merge the fragment with the new data from the server
        fragment._fragmentPushData({
          attributes: canonical
        });
        return fragment;
      }
      return this.recordData._newFragmentRecordData(this.definition, canonical);
    }
    willCommit(fragment) {
      (true && !(fragment instanceof _private.RecordData) && (0, _debug.assert)('Fragment value must be a RecordData', fragment instanceof _private.RecordData));
      fragment._fragmentWillCommit();
    }
    didCommit(fragment, canonical) {
      (true && !(fragment === null || fragment instanceof _private.RecordData) && (0, _debug.assert)('Fragment value must be a RecordData', fragment === null || fragment instanceof _private.RecordData));
      (true && !(canonical == null || (0, _utils.typeOf)(canonical) === 'object') && (0, _debug.assert)('Fragment canonical value must be an object', canonical == null || (0, _utils.typeOf)(canonical) === 'object'));
      if (canonical == null) {
        fragment?._fragmentDidCommit(null);
        if (canonical === null) {
          // server replaced fragment with null
          return null;
        }

        // server confirmed in-flight fragment
        return fragment;
      }
      if (fragment) {
        // merge the fragment with the new data from the server
        fragment._fragmentDidCommit({
          attributes: canonical
        });
        return fragment;
      }
      return this.recordData._newFragmentRecordData(this.definition, canonical);
    }
    commitWasRejected(fragment) {
      (true && !(fragment instanceof _private.RecordData) && (0, _debug.assert)('Fragment value must be a RecordData', fragment instanceof _private.RecordData));
      fragment._fragmentCommitWasRejected();
    }
    rollback(fragment) {
      (true && !(fragment instanceof _private.RecordData) && (0, _debug.assert)('Fragment value must be a RecordData', fragment instanceof _private.RecordData));
      fragment._fragmentRollbackAttributes();
    }
    unload(fragment) {
      (true && !(fragment instanceof _private.RecordData) && (0, _debug.assert)('Fragment value must be a RecordData', fragment instanceof _private.RecordData));
      fragment._fragmentUnloadRecord();
    }
    isDirty(value, originalValue) {
      (true && !(value === null || value instanceof _private.RecordData) && (0, _debug.assert)('Fragment value must be a RecordData', value === null || value instanceof _private.RecordData));
      (true && !(originalValue === null || originalValue instanceof _private.RecordData) && (0, _debug.assert)('Fragment original value must be a RecordData', originalValue === null || originalValue instanceof _private.RecordData));
      return value !== originalValue || value !== null && value.hasChangedAttributes();
    }
    currentState(fragment) {
      (true && !(fragment === null || fragment instanceof _private.RecordData) && (0, _debug.assert)('Fragment value must be a RecordData', fragment === null || fragment instanceof _private.RecordData));
      return fragment === null ? null : fragment.getCurrentState();
    }
    canonicalState(fragment) {
      (true && !(fragment === null || fragment instanceof _private.RecordData) && (0, _debug.assert)('Fragment value must be a RecordData', fragment === null || fragment instanceof _private.RecordData));
      return fragment === null ? null : fragment.getCanonicalState();
    }
  }
  class FragmentArrayBehavior {
    constructor(recordData, definition) {
      this.recordData = recordData;
      this.definition = definition;
    }
    getDefaultValue(key) {
      const {
        options
      } = this.definition;
      if (options.defaultValue === undefined) {
        return [];
      }
      let defaultValue;
      if (typeof options.defaultValue === 'function') {
        const record = this.recordData._fragmentGetRecord();
        defaultValue = options.defaultValue.call(null, record, options, key);
      } else {
        defaultValue = options.defaultValue;
      }
      (true && !(defaultValue === null || (0, _array.isArray)(defaultValue) && defaultValue.every(v => (0, _utils.typeOf)(v) === 'object' || (0, _fragment.isFragment)(v))) && (0, _debug.assert)("The fragment array's default value must be an array of fragments or null", defaultValue === null || (0, _array.isArray)(defaultValue) && defaultValue.every(v => (0, _utils.typeOf)(v) === 'object' || (0, _fragment.isFragment)(v))));
      if (defaultValue === null) {
        return null;
      }
      return defaultValue.map(item => {
        if ((0, _fragment.isFragment)(item)) {
          (true && !((0, _instanceOfType.default)(item.store.modelFor(this.definition.modelName), item)) && (0, _debug.assert)(`The fragment array's default value can only include '${this.definition.modelName}' fragments`, (0, _instanceOfType.default)(item.store.modelFor(this.definition.modelName), item)));
          const recordData = (0, _private3.recordDataFor)(defaultValue);
          recordData.setFragmentOwner(this.recordData, key);
          return recordData;
        }
        return this.recordData._newFragmentRecordData(this.definition, item);
      });
    }
    pushData(fragmentArray, canonical) {
      (true && !(fragmentArray === null || (0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)) && (0, _debug.assert)('Fragment array value must be an array of RecordData', fragmentArray === null || (0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)));
      (true && !(canonical === null || (0, _array.isArray)(canonical) && canonical.every(v => (0, _utils.typeOf)(v) === 'object')) && (0, _debug.assert)('Fragment array canonical value must be an array of objects', canonical === null || (0, _array.isArray)(canonical) && canonical.every(v => (0, _utils.typeOf)(v) === 'object')));
      if (canonical === null) {
        // push replaced fragment array with null
        return null;
      }

      // merge the fragment array with the pushed data
      return canonical.map((attributes, i) => {
        const fragment = fragmentArray?.[i];
        if (fragment) {
          fragment._fragmentPushData({
            attributes
          });
          return fragment;
        } else {
          return this.recordData._newFragmentRecordData(this.definition, attributes);
        }
      });
    }
    willCommit(fragmentArray) {
      (true && !((0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)) && (0, _debug.assert)('Fragment array value must be an array of RecordData', (0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)));
      fragmentArray.forEach(fragment => fragment._fragmentWillCommit());
    }
    didCommit(fragmentArray, canonical) {
      (true && !(fragmentArray === null || (0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)) && (0, _debug.assert)('Fragment array value must be an array of RecordData', fragmentArray === null || (0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)));
      (true && !(canonical == null || (0, _array.isArray)(canonical) && canonical.every(v => (0, _utils.typeOf)(v) === 'object')) && (0, _debug.assert)('Fragment array canonical value must be an array of objects', canonical == null || (0, _array.isArray)(canonical) && canonical.every(v => (0, _utils.typeOf)(v) === 'object')));
      if (canonical == null) {
        fragmentArray?.forEach(fragment => fragment._fragmentDidCommit(null));
        if (canonical === null) {
          // server replaced fragment array with null
          return null;
        }
        // server confirmed in-flight fragments
        return fragmentArray;
      }

      // merge the fragment array with the new data from the server
      const result = canonical.map((attributes, i) => {
        const fragment = fragmentArray?.[i];
        if (fragment) {
          fragment._fragmentDidCommit({
            attributes
          });
          return fragment;
        } else {
          return this.recordData._newFragmentRecordData(this.definition, attributes);
        }
      });

      // cleanup the remaining fragments
      for (let i = canonical.length; i < fragmentArray?.length; ++i) {
        fragmentArray[i]._fragmentDidCommit(null);
      }
      return result;
    }
    commitWasRejected(fragmentArray) {
      (true && !((0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)) && (0, _debug.assert)('Fragment array value must be an array of RecordData', (0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)));
      fragmentArray.forEach(fragment => fragment._fragmentCommitWasRejected());
    }
    rollback(fragmentArray) {
      (true && !((0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)) && (0, _debug.assert)('Fragment array value must be an array of RecordData', (0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)));
      fragmentArray.forEach(fragment => fragment._fragmentRollbackAttributes());
    }
    unload(fragmentArray) {
      (true && !((0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)) && (0, _debug.assert)('Fragment array value must be an array of RecordData', (0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)));
      fragmentArray.forEach(fragment => fragment._fragmentUnloadRecord());
    }
    isDirty(value, originalValue) {
      (true && !(value === null || (0, _array.isArray)(value) && value.every(rd => rd instanceof _private.RecordData)) && (0, _debug.assert)('Fragment array value must be an array of RecordData', value === null || (0, _array.isArray)(value) && value.every(rd => rd instanceof _private.RecordData)));
      (true && !(originalValue === null || (0, _array.isArray)(originalValue) && originalValue.every(rd => rd instanceof _private.RecordData)) && (0, _debug.assert)('Fragment array original value must be an array of RecordData', originalValue === null || (0, _array.isArray)(originalValue) && originalValue.every(rd => rd instanceof _private.RecordData)));
      return !isArrayEqual(value, originalValue) || value !== null && value.some(rd => rd.hasChangedAttributes());
    }
    currentState(fragmentArray) {
      (true && !(fragmentArray === null || (0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)) && (0, _debug.assert)('Fragment array value must be an array of RecordData', fragmentArray === null || (0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)));
      return fragmentArray === null ? null : fragmentArray.map(fragment => fragment.getCurrentState());
    }
    canonicalState(fragmentArray) {
      (true && !(fragmentArray === null || (0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)) && (0, _debug.assert)('Fragment array value must be an array of RecordData', fragmentArray === null || (0, _array.isArray)(fragmentArray) && fragmentArray.every(rd => rd instanceof _private.RecordData)));
      return fragmentArray === null ? null : fragmentArray.map(fragment => fragment.getCanonicalState());
    }
  }
  class ArrayBehavior {
    constructor(recordData, definition) {
      this.recordData = recordData;
      this.definition = definition;
    }
    getDefaultValue(key) {
      const {
        options
      } = this.definition;
      if (options.defaultValue === undefined) {
        return [];
      }
      let defaultValue;
      if (typeof options.defaultValue === 'function') {
        const record = this.recordData._fragmentGetRecord();
        defaultValue = options.defaultValue.call(null, record, options, key);
      } else {
        defaultValue = options.defaultValue;
      }
      (true && !(defaultValue === null || (0, _array.isArray)(defaultValue)) && (0, _debug.assert)("The array's default value must be an array or null", defaultValue === null || (0, _array.isArray)(defaultValue)));
      if (defaultValue === null) {
        return null;
      }
      return defaultValue.slice();
    }
    pushData(array, canonical) {
      (true && !(array === null || (0, _array.isArray)(array)) && (0, _debug.assert)('Array value must be an array', array === null || (0, _array.isArray)(array)));
      (true && !(canonical === null || (0, _array.isArray)(canonical)) && (0, _debug.assert)('Array canonical value must be an array', canonical === null || (0, _array.isArray)(canonical)));
      if (canonical === null) {
        return null;
      }
      return canonical.slice();
    }
    willCommit(array) {
      (true && !((0, _array.isArray)(array)) && (0, _debug.assert)('Array value must be an array', (0, _array.isArray)(array))); // nothing to do
    }
    didCommit(array, canonical) {
      (true && !(array === null || (0, _array.isArray)(array)) && (0, _debug.assert)('Array value must be an array', array === null || (0, _array.isArray)(array)));
      (true && !(canonical === null || canonical === undefined || (0, _array.isArray)(canonical)) && (0, _debug.assert)('Array canonical value must be an array', canonical === null || canonical === undefined || (0, _array.isArray)(canonical)));
      if (canonical === null) {
        // server replaced array with null
        return null;
      }
      if (canonical === undefined) {
        // server confirmed in-flight array
        return array;
      }
      // server returned new canonical data
      return canonical.slice();
    }
    commitWasRejected(array) {
      (true && !((0, _array.isArray)(array)) && (0, _debug.assert)('Array value must be an array', (0, _array.isArray)(array))); // nothing to do
    }
    rollback(array) {
      (true && !((0, _array.isArray)(array)) && (0, _debug.assert)('Array value must be an array', (0, _array.isArray)(array))); // nothing to do
    }
    unload(array) {
      (true && !((0, _array.isArray)(array)) && (0, _debug.assert)('Array value must be an array', (0, _array.isArray)(array))); // nothing to do
    }
    isDirty(value, originalValue) {
      (true && !(value === null || (0, _array.isArray)(value)) && (0, _debug.assert)('Array value must be an array', value === null || (0, _array.isArray)(value)));
      (true && !(originalValue === null || (0, _array.isArray)(originalValue)) && (0, _debug.assert)('Array original value must be an array', originalValue === null || (0, _array.isArray)(originalValue)));
      return !isArrayEqual(value, originalValue);
    }
    currentState(array) {
      (true && !(array === null || (0, _array.isArray)(array)) && (0, _debug.assert)('Array value must be an array', array === null || (0, _array.isArray)(array)));
      return array === null ? null : array.slice();
    }
    canonicalState(array) {
      (true && !(array === null || (0, _array.isArray)(array)) && (0, _debug.assert)('Array value must be an array', array === null || (0, _array.isArray)(array)));
      return array === null ? null : array.slice();
    }
  }
  class FragmentRecordData extends _private.RecordData {
    constructor(identifier, storeWrapper) {
      super(identifier, storeWrapper);
      const behavior = Object.create(null);
      const definitions = this.storeWrapper.attributesDefinitionFor(this.modelName);
      for (const [key, definition] of Object.entries(definitions)) {
        if (!definition.isFragment) {
          continue;
        }
        switch (definition.kind) {
          case 'fragment-array':
            behavior[key] = new FragmentArrayBehavior(this, definition);
            break;
          case 'fragment':
            behavior[key] = new FragmentBehavior(this, definition);
            break;
          case 'array':
            behavior[key] = new ArrayBehavior(this, definition);
            break;
          default:
            (true && !(false) && (0, _debug.assert)(`Unsupported fragment type: ${definition.kind}`));
            break;
        }
      }
      this._fragmentBehavior = behavior;
    }
    _getFragmentDefault(key) {
      const behavior = this._fragmentBehavior[key];
      (true && !(behavior != null) && (0, _debug.assert)(`Attribute '${key}' for model '${this.modelName}' must be a fragment`, behavior != null));
      (true && !(!this.hasFragment(key)) && (0, _debug.assert)('Fragment default value was already initialized', !this.hasFragment(key)));
      const defaultValue = behavior.getDefaultValue(key);
      this._fragmentData[key] = defaultValue;
      return defaultValue;
    }
    getFragment(key) {
      if (key in this._fragments) {
        return this._fragments[key];
      } else if (key in this._inFlightFragments) {
        return this._inFlightFragments[key];
      } else if (key in this._fragmentData) {
        return this._fragmentData[key];
      } else {
        return this._getFragmentDefault(key);
      }
    }
    hasFragment(key) {
      return key in this._fragments || key in this._inFlightFragments || key in this._fragmentData;
    }
    setDirtyFragment(key, value) {
      const behavior = this._fragmentBehavior[key];
      (true && !(behavior != null) && (0, _debug.assert)(`Attribute '${key}' for model '${this.modelName}' must be a fragment`, behavior != null));
      let originalValue;
      if (key in this._inFlightFragments) {
        originalValue = this._inFlightFragments[key];
      } else if (key in this._fragmentData) {
        originalValue = this._fragmentData[key];
      } else {
        originalValue = this._getFragmentDefault(key);
      }
      const isDirty = behavior.isDirty(value, originalValue);
      const oldDirty = this.isFragmentDirty(key);
      if (isDirty !== oldDirty) {
        this.notifyStateChange(key);
      }
      if (isDirty) {
        this._fragments[key] = value;
        this.fragmentDidDirty();
      } else {
        delete this._fragments[key];
        this.fragmentDidReset();
      }
      // this._fragmentArrayCache[key]?.notify();
    }
    setDirtyAttribute(key, value) {
      (true && !(this._fragmentBehavior[key] == null) && (0, _debug.assert)(`Attribute '${key}' for model '${this.modelName}' must not be a fragment`, this._fragmentBehavior[key] == null));
      const oldDirty = this.isAttrDirty(key);
      super.setDirtyAttribute(key, value);
      const isDirty = this.isAttrDirty(key);
      if (isDirty !== oldDirty) {
        this.notifyStateChange(key);
      }
      if (isDirty) {
        this.fragmentDidDirty();
      } else {
        this.fragmentDidReset();
      }
    }
    getFragmentOwner() {
      return this._fragmentOwner?.recordData;
    }
    setFragmentOwner(recordData, key) {
      (true && !(recordData instanceof _private.RecordData) && (0, _debug.assert)('Fragment owner must be a RecordData', recordData instanceof _private.RecordData));
      (true && !(recordData._fragmentBehavior[key] != null) && (0, _debug.assert)('Fragment owner key must be a fragment', recordData._fragmentBehavior[key] != null));
      (true && !(!this._fragmentOwner || this._fragmentOwner.recordData === recordData) && (0, _debug.assert)('Fragments can only belong to one owner, try copying instead', !this._fragmentOwner || this._fragmentOwner.recordData === recordData));
      this._fragmentOwner = {
        recordData,
        key
      };
    }
    _newFragmentRecordData(definition, attributes) {
      const type = (0, _fragment.getActualFragmentType)(definition.modelName, definition.options, attributes, this._fragmentGetRecord());
      const recordData = this.storeWrapper.recordDataFor(type);
      recordData.setFragmentOwner(this, definition.name);
      recordData._fragmentPushData({
        attributes
      });
      return recordData;
    }
    hasChangedAttributes() {
      return super.hasChangedAttributes() || this.hasChangedFragments();
    }
    hasChangedFragments() {
      return Object.keys(this._fragmentsOrInFlight).length > 0;
    }
    isFragmentDirty(key) {
      return this.__fragments?.[key] !== undefined;
    }
    getCanonicalState() {
      const result = Object.assign({}, this._data);
      for (const [key, behavior] of Object.entries(this._fragmentBehavior)) {
        const value = key in this._fragmentData ? this._fragmentData[key] : this._getFragmentDefault(key);
        result[key] = behavior.canonicalState(value);
      }
      return result;
    }
    getCurrentState() {
      const result = Object.assign({}, this._data, this._inFlightAttributes, this._attributes);
      for (const [key, behavior] of Object.entries(this._fragmentBehavior)) {
        result[key] = behavior.currentState(this.getFragment(key));
      }
      return result;
    }

    /*
        Returns an object, whose keys are changed properties, and value is an
        [oldProp, newProp] array.
         @method changedAttributes
        @private
      */
    changedAttributes() {
      const result = super.changedAttributes();
      if (this.hasChangedFragments()) {
        Object.assign(result, this.changedFragments());
      }
      return result;
    }
    changedFragments() {
      const diffData = Object.create(null);
      for (const [key, newFragment] of Object.entries(this._fragmentsOrInFlight)) {
        const behavior = this._fragmentBehavior[key];
        const oldFragment = this._fragmentData[key];
        diffData[key] = [behavior.canonicalState(oldFragment), behavior.currentState(newFragment)];
      }
      return diffData;
    }
    _changedFragmentKeys(updates) {
      const changedKeys = [];
      const original = Object.assign({}, this._fragmentData, this._inFlightFragments);
      for (const key of Object.keys(updates)) {
        if (this._fragments[key]) {
          continue;
        }
        const eitherIsNull = original[key] === null || updates[key] === null;
        if (eitherIsNull || (0, _private2.diffArray)(original[key], updates[key]).firstChangeIndex !== null) {
          changedKeys.push(key);
        }
      }
      return changedKeys;
    }
    pushData(data, calculateChange) {
      let changedFragmentKeys;
      const subFragmentsToProcess = [];
      if (data.attributes) {
        // copy so that we don't mutate the caller's data
        const attributes = Object.assign({}, data.attributes);
        data = Object.assign({}, data, {
          attributes
        });
        for (const [key, behavior] of Object.entries(this._fragmentBehavior)) {
          const canonical = data.attributes[key];
          if (canonical === undefined) {
            continue;
          }
          // strip fragments from the attributes so the super call does not process them
          delete data.attributes[key];
          subFragmentsToProcess.push({
            key,
            behavior,
            canonical,
            attributes
          });
        }
      }

      // Wee need first the attributes to be setup before the fragment, to be able to access them (for polymorphic fragments for example)
      const changedAttributeKeys = super.pushData(data, calculateChange);
      if (data.attributes) {
        const newCanonicalFragments = {};
        subFragmentsToProcess.forEach(({
          key,
          behavior,
          canonical
        }) => {
          const current = key in this._fragmentData ? this._fragmentData[key] : this._getFragmentDefault(key);
          newCanonicalFragments[key] = behavior.pushData(current, canonical);
        });
        if (calculateChange) {
          changedFragmentKeys = this._changedFragmentKeys(newCanonicalFragments);
        }
        Object.assign(this._fragmentData, newCanonicalFragments);
        // update fragment arrays
        changedFragmentKeys?.forEach(key => this._fragmentArrayCache[key]?.notify());
      }
      const changedKeys = mergeArrays(changedAttributeKeys, changedFragmentKeys);
      if (true && changedKeys?.length > 0) {
        internalModelFor(this).notifyAttributes(changedKeys);
      }
      // on ember-data 2.8 - 4.4, InternalModel.setupData will notify
      return changedKeys || [];
    }
    willCommit() {
      for (const [key, behavior] of Object.entries(this._fragmentBehavior)) {
        const data = this.getFragment(key);
        if (data) {
          behavior.willCommit(data);
        }
      }
      this._inFlightFragments = this._fragments;
      this._fragments = null;
      // this.notifyStateChange();
      super.willCommit();
    }

    /**
     * Checks if the fragments which are considered as changed are still
     * different to the state which is acknowledged by the server.
     *
     * This method is needed when data for the internal model is pushed and the
     * pushed data might acknowledge dirty attributes as confirmed.
     */
    _updateChangedFragments() {
      for (const key of Object.keys(this._fragments)) {
        const value = this._fragments[key];
        const originalValue = this._fragmentData[key];
        const behavior = this._fragmentBehavior[key];
        const isDirty = behavior.isDirty(value, originalValue);
        if (!isDirty) {
          delete this._fragments[key];
        }
      }
    }
    didCommit(data) {
      if (data?.attributes) {
        // copy so that we don't mutate the caller's data
        const attributes = Object.assign({}, data.attributes);
        data = Object.assign({}, data, {
          attributes
        });
      }
      const newCanonicalFragments = {};
      for (const [key, behavior] of Object.entries(this._fragmentBehavior)) {
        let canonical;
        if (data?.attributes) {
          // strip fragments from the attributes so the super call does not process them
          canonical = data.attributes[key];
          delete data.attributes[key];
        }
        const fragment = key in this._inFlightFragments ? this._inFlightFragments[key] : this._fragmentData[key];
        newCanonicalFragments[key] = behavior.didCommit(fragment, canonical);
      }
      const changedFragmentKeys = this._changedFragmentKeys(newCanonicalFragments);
      Object.assign(this._fragmentData, newCanonicalFragments);
      this._inFlightFragments = null;
      this._updateChangedFragments();
      const changedAttributeKeys = super.didCommit(data);

      // update fragment arrays
      Object.keys(newCanonicalFragments).forEach(key => this._fragmentArrayCache[key]?.notify());
      const changedKeys = mergeArrays(changedAttributeKeys, changedFragmentKeys);
      if (true && changedKeys?.length > 0) {
        internalModelFor(this).notifyAttributes(changedKeys);
      }
      // on ember-data 2.8 - 4.4, InternalModel.adapterDidCommit will notify
      return changedKeys;
    }
    commitWasRejected(identifier, errors) {
      for (const [key, behavior] of Object.entries(this._fragmentBehavior)) {
        const fragment = key in this._inFlightFragments ? this._inFlightFragments[key] : this._fragmentData[key];
        if (fragment == null) {
          continue;
        }
        behavior.commitWasRejected(fragment);
      }
      Object.assign(this._fragments, this._inFlightFragments);
      this._inFlightFragments = null;
      super.commitWasRejected(identifier, errors);
    }
    rollbackAttributes() {
      let dirtyFragmentKeys;
      if (this.hasChangedFragments()) {
        dirtyFragmentKeys = Object.keys(this._fragments);
        dirtyFragmentKeys.forEach(key => {
          this.rollbackFragment(key);
        });
        this._fragments = null;
      }
      const dirtyAttributeKeys = super.rollbackAttributes();
      this.notifyStateChange();
      this.fragmentDidReset();
      return mergeArrays(dirtyAttributeKeys, dirtyFragmentKeys);
    }
    rollbackFragment(key) {
      const behavior = this._fragmentBehavior[key];
      (true && !(behavior != null) && (0, _debug.assert)(`Attribute '${key}' for model '${this.modelName}' must be a fragment`, behavior != null));
      if (!this.isFragmentDirty(key)) {
        return;
      }
      delete this._fragments[key];
      const fragment = this._fragmentData[key];
      if (fragment == null) {
        return;
      }
      behavior.rollback(fragment);
      this._fragmentArrayCache[key]?.notify();
      if (!this.hasChangedAttributes()) {
        this.notifyStateChange(key);
        this.fragmentDidReset();
      }
    }
    reset() {
      super.reset();
      this.__fragments = null;
      this.__inFlightFragments = null;
      this.__fragmentData = null;
      this.__fragmentArrayCache = null;
      this._fragmentOwner = null;
    }
    unloadRecord() {
      for (const [key, behavior] of Object.entries(this._fragmentBehavior)) {
        const fragment = this._fragments[key];
        if (fragment != null) {
          behavior.unload(fragment);
        }
        const inFlight = this._inFlightFragments[key];
        if (inFlight != null) {
          behavior.unload(inFlight);
        }
        const fragmentData = this._fragmentData[key];
        if (fragmentData != null) {
          behavior.unload(fragmentData);
        }
        this._fragmentArrayCache[key]?.destroy();
      }
      super.unloadRecord();
    }

    /**
     * When a fragment becomes dirty, update the dirty state of the fragment's owner
     */
    fragmentDidDirty() {
      (true && !(this.hasChangedAttributes()) && (0, _debug.assert)('Fragment is not dirty', this.hasChangedAttributes()));
      if (!this._fragmentOwner) {
        return;
      }
      const {
        recordData: owner,
        key
      } = this._fragmentOwner;
      if (owner.isFragmentDirty(key)) {
        // fragment owner is already dirty
        return;
      }
      (true && !(key in owner._fragmentData) && (0, _debug.assert)(`Fragment '${key}' in owner '${owner.modelName}' has not been initialized`, key in owner._fragmentData));
      owner._fragments[key] = owner._fragmentData[key];
      owner.notifyStateChange(key);
      owner.fragmentDidDirty();
    }

    /**
     * When a fragment becomes clean, update the fragment owner
     */
    fragmentDidReset() {
      if (!this._fragmentOwner) {
        return;
      }
      const {
        recordData: owner,
        key
      } = this._fragmentOwner;
      if (!owner.isFragmentDirty(key)) {
        // fragment owner is already clean
        return;
      }
      const behavior = owner._fragmentBehavior[key];
      const value = owner.getFragment(key);
      const originalValue = owner._fragmentData[key];
      const isDirty = behavior.isDirty(value, originalValue);
      if (isDirty) {
        // fragment is still dirty
        return;
      }
      delete owner._fragments[key];
      owner.notifyStateChange(key);
      owner.fragmentDidReset();
    }
    notifyStateChange(key) {
      if (key && true) {
        this.storeWrapper.notifyPropertyChange(this.modelName, this.id, this.clientId, key);
      } else {
        this.storeWrapper.notifyStateChange(this.modelName, this.id, this.clientId, key);
      }
    }

    /*
     * Ensure that any changes to the fragment record-data also update the InternalModel's
     * state machine and fire property change notifications on the Record
     */

    _fragmentGetRecord(properties) {
      if (true) {
        return this.storeWrapper._store._instanceCache.getRecord(this.identifier, properties);
      }
      return internalModelFor(this).getRecord(properties);
    }
    _fragmentPushData(data) {
      internalModelFor(this).setupData(data);
    }
    _fragmentWillCommit() {
      internalModelFor(this).adapterWillCommit();
    }
    _fragmentDidCommit(data) {
      internalModelFor(this).adapterDidCommit(data);
    }
    _fragmentRollbackAttributes() {
      internalModelFor(this).rollbackAttributes();
    }
    _fragmentCommitWasRejected() {
      internalModelFor(this).adapterDidInvalidate();
    }
    _fragmentUnloadRecord() {
      internalModelFor(this).unloadRecord();
    }

    /**
     * The current dirty state
     */
    get _fragments() {
      if (this.__fragments === null) {
        this.__fragments = Object.create(null);
      }
      return this.__fragments;
    }
    set _fragments(v) {
      this.__fragments = v;
    }

    /**
     * The current saved state
     */
    get _fragmentData() {
      if (this.__fragmentData === null) {
        this.__fragmentData = Object.create(null);
      }
      return this.__fragmentData;
    }
    set _fragmentData(v) {
      this.__fragmentData = v;
    }

    /**
     * The fragments which are currently being saved to the backend
     */
    get _inFlightFragments() {
      if (this.__inFlightFragments === null) {
        this.__inFlightFragments = Object.create(null);
      }
      return this.__inFlightFragments;
    }
    set _inFlightFragments(v) {
      this.__inFlightFragments = v;
    }
    get _fragmentsOrInFlight() {
      return this.__inFlightFragments && Object.keys(this.__inFlightFragments).length > 0 ? this.__inFlightFragments : this.__fragments || {};
    }

    /**
     * Fragment array instances
     *
     * This likely belongs in InternalModel, since ember-data caches its has-many
     * arrays in `InternalModel#_manyArrayCache`. But we can't extend InternalModel.
     */
    get _fragmentArrayCache() {
      if (this.__fragmentArrayCache === null) {
        this.__fragmentArrayCache = Object.create(null);
      }
      return this.__fragmentArrayCache;
    }
  }
  _exports.default = FragmentRecordData;
  function internalModelFor(recordData) {
    const store = recordData.storeWrapper._store;
    if (true) {
      return store._instanceCache._internalModelForResource(recordData.identifier);
    }
    return store._internalModelForResource(recordData.identifier);
  }
  function isArrayEqual(a, b) {
    if (a === b) {
      return true;
    }
    if (a === null || b === null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
  function mergeArrays(a, b) {
    if (b == null) {
      return a;
    }
    if (a == null) {
      return b;
    }
    return [...a, ...b];
  }
});