define("ember-leaflet/components/base-layer", ["exports", "@ember/component", "@ember/debug", "@ember/object", "@ember/service", "@glimmer/component", "@ember/runloop", "@ember/string", "@ember/template-factory"], function (_exports, _component, _debug, _object, _service, _component2, _runloop, _string, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.leafletDescriptorsProps as |prop|}}
    {{#if (ember-leaflet-eq prop "location")}}
      {{did-update (fn this.updateOption "location") this.location}}
    {{else}}
      {{did-update (fn this.updateOption prop) (get this.args prop)}}
    {{/if}}
  {{/each}}
  
  {{#each this.leafletStyleProperties as |prop|}}
    {{did-update (fn this.updateStyleProperty prop) (get this.args prop)}}
  {{/each}}
  
  <@node @didInsertParent={{this.didInsertParent}} @willDestroyParent={{this.willDestroyParent}} as |Node|>
    {{#let (ember-leaflet-hash) as |components|}}
      {{#each this.componentsToYield as |c|}}
        {{ember-leaflet-assign-to
          components
          key=(ember-leaflet-or c.as c.name)
          value=(component (ensure-safe-component (ember-leaflet-or c.component c.name)) parent=this node=Node)
          onChange=this.mergeComponents
        }}
      {{/each}}
  
      {{yield this.mergedComponents}}
    {{/let}}
  </@node>
  */
  {
    "id": "MNcAnGoY",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"leafletDescriptorsProps\"]]],null]],null],null,[[[41,[28,[37,3],[[30,1],\"location\"],null],[[[1,\"    \"],[1,[28,[35,4],[[28,[37,5],[[30,0,[\"updateOption\"]],\"location\"],null],[30,0,[\"location\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,4],[[28,[37,5],[[30,0,[\"updateOption\"]],[30,1]],null],[28,[37,6],[[30,0,[\"args\"]],[30,1]],null]],null]],[1,\"\\n\"]],[]]]],[1]],null],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"leafletStyleProperties\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,4],[[28,[37,5],[[30,0,[\"updateStyleProperty\"]],[30,2]],null],[28,[37,6],[[30,0,[\"args\"]],[30,2]],null]],null]],[1,\"\\n\"]],[2]],null],[1,\"\\n\"],[8,[30,3],null,[[\"@didInsertParent\",\"@willDestroyParent\"],[[30,0,[\"didInsertParent\"]],[30,0,[\"willDestroyParent\"]]]],[[\"default\"],[[[[1,\"\\n\"],[44,[[28,[37,8],null,null]],[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"componentsToYield\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,9],[[30,5]],[[\"key\",\"value\",\"onChange\"],[[28,[37,10],[[30,6,[\"as\"]],[30,6,[\"name\"]]],null],[50,[28,[37,12],[[28,[37,10],[[30,6,[\"component\"]],[30,6,[\"name\"]]],null]],null],0,null,[[\"parent\",\"node\"],[[30,0],[30,4]]]],[30,0,[\"mergeComponents\"]]]]]],[1,\"\\n\"]],[6]],null],[1,\"\\n    \"],[18,7,[[30,0,[\"mergedComponents\"]]]],[1,\"\\n\"]],[5]]]],[4]]]]]],[\"prop\",\"prop\",\"@node\",\"Node\",\"components\",\"c\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"ember-leaflet-eq\",\"did-update\",\"fn\",\"get\",\"let\",\"ember-leaflet-hash\",\"ember-leaflet-assign-to\",\"ember-leaflet-or\",\"component\",\"ensure-safe-component\",\"yield\"]]",
    "moduleName": "ember-leaflet/components/base-layer.hbs",
    "isStrictMode": false
  });

  /* global L */

  const leaf = typeof L === 'undefined' ? {} : L;

  /**
   * The base class for all ember-leaflet layer components. It contains common
   * abstractions used on all layers, including setting event listeners,
   * gathering init options, etc.
   *
   * It is meant to be subclassed with the `createLayer` method being
   * mandatory to implement.
   *
   * @class BaseLayer
   * @uses Leaflet
   */
  let BaseLayer = _exports.default = (_class = class BaseLayer extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "L", leaf);
      _initializerDefineProperty(this, "fastboot", _descriptor, this);
      _defineProperty(this, "leafletOptions", [
      /**
       * By default the layer will be added to the map's overlay pane. Overriding this option will
       * cause the layer to be placed on another pane by default.
       *
       * @argument pane
       * @type {String}
       */
      'pane',
      /**
       * String to be shown in the attribution control, e.g. "© OpenStreetMap contributors". It describes the layer
       * data and is often a legal obligation towards copyright holders and tile providers.
       *
       * @argument attribution
       * @type {String}
       */
      'attribution']);
      _defineProperty(this, "leafletEvents", [
      /**
       * Fired after the layer is added to a map.
       *
       * @argument onAdd
       * @type {Function}
       */
      'add',
      /**
       * Fired after the layer is removed from a map.
       *
       * @argument onRemove
       * @type {Function}
       */
      'remove',
      /**
       * Fired when a popup bound to this layer is opened.
       *
       * @argument onPopupopen
       * @type {Function}
       */
      'popupopen',
      /**
       * Fired when a popup bound to this layer is closed.
       *
       * @argument onPopupclose
       * @type {Function}
       */
      'popupclose',
      /**
       * Fired when a tooltip bound to this layer is opened.
       *
       * @argument onTooltipopen
       * @type {Function}
       */
      'tooltipopen',
      /**
       * Fired when a tooltip bound to this layer is closed.
       *
       * @argument onTooltipclose
       * @type {Function}
       */
      'tooltipclose']);
      _defineProperty(this, "leafletRequiredOptions", []);
      _defineProperty(this, "leafletStyleProperties", []);
      // This is an array that describes how a component's arguments
      // relate to leaflet methods and their parameters.
      // E.g: Changing the tile layer's `@url` should invoke the layer's `setUrl` method
      // with the new `@url` value
      _defineProperty(this, "leafletDescriptors", []);
      // This array allows subclasses to avoid declaring a template
      // if the sole purpose is to yield additional components, typical in addons
      _defineProperty(this, "componentsToYield", []);
    }
    mergeComponents(obj) {
      if (!this.mergedComponents) {
        this.mergedComponents = obj;
      } else {
        Object.assign(this.mergedComponents, obj);
      }
    }
    createLayer() {
      (true && !(false) && (0, _debug.assert)("BaseLayer's `createLayer` should be overriden."));
    }
    didCreateLayer() {}
    willDestroyLayer() {}

    /*
     * Method called by parent when the layer needs to setup
     */
    didInsertParent(element) {
      // Check for fastBoot
      if (this.fastboot?.isFastBoot) {
        return;
      }
      this._layer = this.createLayer(element);
      this._addEventListeners();
      if (this.args.parent) {
        this.addToContainer();
      }
      this.didCreateLayer();
    }

    /*
     * Default logic for adding the layer to the container
     */
    addToContainer() {
      this.args.parent._layer.addLayer(this._layer);
    }

    /*
     * Method called by parent when the layer needs to teardown
     */
    willDestroyParent() {
      // Check for fastBoot
      if (this.fastboot?.isFastBoot) {
        return;
      }
      this.willDestroyLayer();
      this._removeEventListeners();
      if (this.args.parent && this._layer) {
        this.removeFromContainer();
      }
      delete this._layer;
    }

    /*
     * Default logic for removing the layer from the container
     */
    removeFromContainer() {
      this.args.parent._layer.removeLayer(this._layer);
    }
    get options() {
      let options = {};
      for (let optionName of this.leafletOptions) {
        if (this.args[optionName] !== undefined) {
          options[optionName] = this.args[optionName];
        }
      }
      return options;
    }
    get requiredOptions() {
      let options = [];
      for (let optionName of this.leafletRequiredOptions) {
        let value = this.args[optionName] || this[optionName];
        (true && !(value) && (0, _debug.assert)(`\`${optionName}\` is a required option but its value was \`${value}\``, value));
        options.push(value);
      }
      return options;
    }
    get usedLeafletEvents() {
      return this.leafletEvents.filter(eventName => {
        let methodName = `_${eventName}`;
        let actionName = `on${(0, _string.classify)(eventName)}`;
        return this[methodName] !== undefined || this.args[actionName] !== undefined;
      });
    }
    _addEventListeners() {
      this._eventHandlers = {};
      for (let eventName of this.usedLeafletEvents) {
        let actionName = `on${(0, _string.classify)(eventName)}`;
        let methodName = `_${eventName}`;

        // create an event handler that runs the function inside an event loop.
        this._eventHandlers[eventName] = function (e) {
          let fn = () => {
            // try to invoke/send an action for this event
            if (typeof this.args[actionName] === 'function') {
              this.args[actionName](e);
            }

            // allow classes to add custom logic on events as well
            if (typeof this[methodName] === 'function') {
              this[methodName](e);
            }
          };
          (0, _runloop.scheduleOnce)('actions', this, fn);
        };
        this._layer.addEventListener(eventName, this._eventHandlers[eventName], this);
      }
    }
    _removeEventListeners() {
      if (this._eventHandlers) {
        for (let eventName of this.usedLeafletEvents) {
          this._layer.removeEventListener(eventName, this._eventHandlers[eventName], this);
          delete this._eventHandlers[eventName];
        }
      }
    }
    get leafletDescriptorsProps() {
      return this.leafletDescriptors.map(d => {
        return typeof d === 'string' ? d.split(':')[0] : d.arg;
      });
    }
    updateOption(arg, [value]) {
      // find the corresponding leaflet descriptor
      let descriptor = this.leafletDescriptors.find(d => {
        let descArg = typeof d === 'string' ? d.split(':')[0] : d.arg;
        return descArg === arg;
      });
      if (!descriptor) {
        return;
      }
      if (typeof descriptor === 'string') {
        let [property, method, ...params] = descriptor.split(':');
        if (!method) {
          method = `set${(0, _string.classify)(property)}`;
        }
        (true && !(!!this._layer[method]) && (0, _debug.assert)(`Leaflet layer must have a ${method} function.`, !!this._layer[method]));
        let methodParams = params.map(p => this.args[p] || this[p]);
        this._layer[method].call(this._layer, value, ...methodParams);
      } else {
        let {
          updateFn,
          params = []
        } = descriptor;
        let methodParams = params.map(p => this.args[p] || this[p]);
        updateFn(this._layer, value, ...methodParams);
      }
      let methodName = `${(0, _string.classify)(arg)}_did_change`;
      if (typeof this[methodName] === 'function') {
        this[methodName](value);
      }
    }
    updateStyleProperty(arg, [value]) {
      this._layer.setStyle({
        [arg]: value
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "mergeComponents", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mergeComponents"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsertParent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertParent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyParent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyParent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateOption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateStyleProperty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateStyleProperty"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BaseLayer);
});