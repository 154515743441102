define("famoca-tree-core/components/famoca-tree/trigger-expand", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="famoca-tree-node-trigger-expand" data-trigger-expand-for={{@trigger-expand-for}}>{{yield}}</span>
  
  */
  {
    "id": "Ku99onNg",
    "block": "[[[10,1],[14,0,\"famoca-tree-node-trigger-expand\"],[15,\"data-trigger-expand-for\",[30,1]],[12],[18,2,null],[13],[1,\"\\n\"]],[\"@trigger-expand-for\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "famoca-tree-core/components/famoca-tree/trigger-expand.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});