define("ember-data-model-fragments/attributes/array", ["exports", "@ember/object", "@ember/array", "@ember/debug", "@ember-data/store/-private", "ember-data-model-fragments/util/meta-type-for", "ember-data-model-fragments/array/stateful"], function (_exports, _object, _array, _debug, _private, _metaTypeFor, _stateful) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = array;
  /**
   `MF.array` defines an attribute on a `DS.Model` or `MF.Fragment`. It creates a
   property that returns an array of values of the given primitive type. The
   array is aware of its original state and so has a `hasDirtyAttributes`
   property and a `rollback` method.
  
   It takes an optional hash as a second parameter, currently supported options
   are:
  
   - `defaultValue`: An array literal or a function to be called to set the
   attribute to a default value if none is supplied. Values are deep copied
   before being used. Note that default values will be passed through the
   fragment's serializer when creating the fragment.
  
   Example
  
   ```javascript
   App.Person = DS.Model.extend({
      aliases: MF.array('string')
    });
   ```
  
   @namespace MF
   @method array
   @param {String} type the type of value contained in the array
   @param {Object} options a hash of options
   @return {Attribute}
   */
  function array(type, options) {
    if (typeof type === 'object') {
      options = type;
      type = undefined;
    } else {
      options || (options = {});
    }
    const metaType = (0, _metaTypeFor.default)('array', type);
    const meta = {
      arrayTransform: type,
      type: metaType,
      isAttribute: true,
      isFragment: true,
      kind: 'array',
      options
    };

    // eslint-disable-next-line ember/require-computed-property-dependencies
    return (0, _object.computed)({
      get(key) {
        const recordData = (0, _private.recordDataFor)(this);
        if (recordData.getFragment(key) === null) {
          return null;
        }
        let array = recordData._fragmentArrayCache[key];
        if (!array) {
          array = _stateful.default.create({
            store: this.store,
            recordData,
            key
          });
          recordData._fragmentArrayCache[key] = array;
        }
        return array;
      },
      set(key, value) {
        (true && !(value === null || (0, _array.isArray)(value)) && (0, _debug.assert)('You must pass an array or null to set an array', value === null || (0, _array.isArray)(value)));
        const recordData = (0, _private.recordDataFor)(this);
        if (value === null) {
          recordData.setDirtyFragment(key, null);
          return null;
        }
        recordData.setDirtyFragment(key, value.slice());
        let array = recordData._fragmentArrayCache[key];
        if (!array) {
          array = _stateful.default.create({
            store: this.store,
            recordData,
            key
          });
          recordData._fragmentArrayCache[key] = array;
        }
        array._setFragments(value);
        return array;
      }
    }).meta(meta);
  }
});