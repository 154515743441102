define("ember-leaflet/components/array-path-layer", ["exports", "ember-leaflet/components/path-layer"], function (_exports, _pathLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * A class that extends `PathLayer` with a `@locations` argument.
   * Layers that require multiple points extend this class.
   *
   * @class ArrayPathLayer
   * @extends ember-leaflet/components/path-layer~PathLayer
   */
  class ArrayPathLayer extends _pathLayer.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "leafletRequiredOptions", [...this.leafletRequiredOptions,
      /**
       * An array of geographical points.
       *
       * @argument locations
       * @type {LatLng[]}
       */
      'locations']);
      _defineProperty(this, "leafletDescriptors", [...this.leafletDescriptors, 'locations:setLatLngs']);
    }
  }
  _exports.default = ArrayPathLayer;
});